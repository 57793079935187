import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={72}
    height={73}
    fill="none"
    viewBox="0 0 72 73"
    preserveAspectRatio="meet"
    {...props}
  >
    <g fill="#313449" clipPath="url(#a)">
      <path d="M36 43.98c4.077 0 7.383-3.34 7.383-7.461S40.077 29.057 36 29.057s-7.383 3.341-7.383 7.462c0 4.12 3.306 7.461 7.383 7.461Z" />
      <path d="M36 .988C16.584.988.844 16.896.844 36.518.844 56.143 16.584 72.05 36 72.05c19.416 0 35.156-15.908 35.156-35.531C71.156 16.896 55.416.988 36 .988Zm21.72 44.561c-.168 3.378-1.107 6.71-3.543 9.145-2.46 2.459-5.771 3.375-9.146 3.544H26.97c-3.375-.17-6.686-1.085-9.146-3.544-2.436-2.436-3.375-5.767-3.543-9.145V27.49c.168-3.379 1.107-6.71 3.543-9.145 2.46-2.46 5.771-3.375 9.146-3.544H45.03c3.375.169 6.686 1.084 9.146 3.544 2.436 2.435 3.375 5.766 3.544 9.144V45.55Z" />
      <path d="M44.803 18.915c-4.4-.122-13.205-.122-17.605 0-2.29.064-4.885.64-6.516 2.4-1.695 1.831-2.336 4.043-2.4 6.516-.115 4.343 0 17.375 0 17.375.074 2.474.705 4.686 2.4 6.516 1.631 1.762 4.227 2.337 6.516 2.4 4.4.123 13.206.123 17.605 0 2.29-.063 4.885-.639 6.517-2.4 1.695-1.83 2.335-4.042 2.4-6.516V27.831c-.065-2.473-.705-4.685-2.4-6.515-1.632-1.762-4.228-2.337-6.517-2.4ZM36 48.085c-2.263 0-4.476-.68-6.358-1.95a11.54 11.54 0 0 1-4.214-5.19 11.68 11.68 0 0 1-.651-6.682 11.605 11.605 0 0 1 3.131-5.922 11.407 11.407 0 0 1 5.86-3.165c2.22-.446 4.52-.217 6.611.658a11.47 11.47 0 0 1 5.136 4.26 11.654 11.654 0 0 1 1.928 6.425c0 3.067-1.205 6.009-3.351 8.178A11.383 11.383 0 0 1 36 48.084ZM47.492 27.34a2.27 2.27 0 0 1-1.271-.39 2.307 2.307 0 0 1-.843-1.038 2.335 2.335 0 0 1 .497-2.521 2.267 2.267 0 0 1 2.494-.501c.418.175.775.471 1.027.852a2.33 2.33 0 0 1-.285 2.92 2.287 2.287 0 0 1-1.619.678Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .135h72v72.767H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
