import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={16}
    fill="none"
    viewBox="0 0 19 16"
    preserveAspectRatio="meet"
    {...props}
  >
    <path
      fill="#313449"
      d="M.714 8.016c0-.325.125-.637.346-.866.22-.23.52-.36.833-.36h12.932L10.032 2.15a1.22 1.22 0 0 1-.286-.388A1.263 1.263 0 0 1 9.945.388a1.18 1.18 0 0 1 .383-.283 1.142 1.142 0 0 1 1.314.256l6.983 6.757a1.265 1.265 0 0 1 0 1.791l-6.983 6.762c-.229.221-.533.34-.846.328a1.161 1.161 0 0 1-.821-.388 1.253 1.253 0 0 1-.317-.878c.011-.325.146-.632.374-.854l4.793-4.639H1.893c-.312 0-.612-.129-.833-.358a1.25 1.25 0 0 1-.346-.866Z"
    />
  </svg>
);
export default SvgComponent;
