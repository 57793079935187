import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={72}
    height={73}
    fill="none"
    viewBox="0 0 72 73"
    preserveAspectRatio="meet"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#313449"
        d="M36 .135a35.73 35.73 0 0 0-20 6.132A36.303 36.303 0 0 0 2.74 22.595 36.744 36.744 0 0 0 .692 43.617a36.505 36.505 0 0 0 9.852 18.629 35.883 35.883 0 0 0 18.433 9.957 35.647 35.647 0 0 0 20.8-2.07 36.085 36.085 0 0 0 16.156-13.4A36.66 36.66 0 0 0 72 36.519c0-9.65-3.793-18.904-10.544-25.727S45.548.135 36 .135Zm16.228 32.329c-3.211.359-5.963-.924-8.715-2.561 0 .268.08 8.579-.057 12.543-.138 4.074-1.578 7.66-4.737 10.335-6.698 5.683-16.205 2.614-18.474-6.075-1.231-4.734-.16-9 3.515-12.375 2.07-1.915 4.623-2.767 7.361-3.121.587-.077 1.18-.11 1.814-.168v6.434c-.805.201-1.62.379-2.42.618-.556.162-1.097.368-1.62.617a5.216 5.216 0 0 0-1.883 1.453 5.288 5.288 0 0 0-1.052 2.144A5.32 5.32 0 0 0 27 46.85a5.696 5.696 0 0 0 2.396 1.926 5.63 5.63 0 0 0 3.037.39c2.07-.253 3.368-1.57 4.135-3.46.531-1.497.761-3.085.678-4.673-.029-7.813-.029-15.62-.043-23.434.007-.08.018-.16.033-.239h6.182c.691 5.228 3.316 8.54 8.81 9.01v6.094Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .135h72v72.767H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
