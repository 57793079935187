import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={72}
    height={73}
    fill="none"
    viewBox="0 0 72 73"
    preserveAspectRatio="meet"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#313449"
        d="M36 .135a35.73 35.73 0 0 0-20 6.132A36.303 36.303 0 0 0 2.74 22.595 36.744 36.744 0 0 0 .692 43.617a36.505 36.505 0 0 0 9.852 18.629 35.883 35.883 0 0 0 18.433 9.957 35.647 35.647 0 0 0 20.8-2.07 36.085 36.085 0 0 0 16.156-13.4A36.66 36.66 0 0 0 72 36.519c0-9.65-3.793-18.904-10.544-25.727S45.548.135 36 .135ZM25.532 55.668h-7.854V30.1h7.854v25.57ZM21.6 26.61a4.537 4.537 0 0 1-2.542-.768 4.61 4.61 0 0 1-1.692-2.065 4.665 4.665 0 0 1-.272-2.667 4.635 4.635 0 0 1 1.24-2.371 4.556 4.556 0 0 1 2.334-1.275 4.526 4.526 0 0 1 2.642.25 4.58 4.58 0 0 1 2.059 1.69c.506.757.778 1.649.783 2.562a4.66 4.66 0 0 1-1.328 3.272 4.561 4.561 0 0 1-3.224 1.372Zm33.347 29.06h-7.849V43.22c0-2.968-.056-6.774-4.083-6.774-4.026 0-4.737 3.227-4.737 6.559v12.662h-7.82V30.1h7.536v3.485h.11c1.046-2.01 3.609-4.131 7.431-4.131 7.949 0 9.412 5.295 9.412 12.17v14.045Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .135h72v72.767H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
