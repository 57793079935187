import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={178}
    height={169}
    fill="none"
    viewBox="0 0 178 169"
    preserveAspectRatio="meet"
    {...props}
  >
    <path
      fill={props.fill || "#AADA3B"}
      d="M51.402 6.46C23.682 8.894-2.583 34.901.255 67.895c2.337 27.173 18.418 46.139 34.723 61.294a289.385 289.385 0 0 0 46.221 34.935c11.61 6.739 23.258 5.715 33.062-2.905a290.898 290.898 0 0 0 39.545-42.424c13.471-17.794 26.075-39.257 23.737-66.43C174.706 19.371 144.394-1.667 116.675.77a45.13 45.13 0 0 0-17.379 5.016 45.586 45.586 0 0 0-14.074 11.459 45.36 45.36 0 0 0-15.828-8.86 45.04 45.04 0 0 0-17.992-1.926Z"
    />
  </svg>
);
export default SvgComponent;
