import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={598}
    height={876}
    fill="none"
    viewBox="0 0 598 876"
    preserveAspectRatio="meet"
    {...props}
  >
    <g>
      <path
        fill="#00CB75"
        d="m310.638 114.44-79.941 8.72c-8.949.976-15.413 9.022-14.437 17.972l20.408 187.098c.976 8.949 9.022 15.413 17.971 14.437l79.942-8.72c8.949-.976 15.413-9.022 14.437-17.971L328.61 128.877c-.976-8.949-9.023-15.413-17.972-14.437Z"
      />
      <path
        fill="#7BB851"
        d="M357.566 410.101 206.159 174.17c-4.863-7.577-14.944-9.776-22.523-4.915l-67.679 43.433a16.3 16.3 0 0 0-4.914 22.522l151.408 235.931c3.456 5.383 9.545 8.052 15.5 7.403a16.248 16.248 0 0 0 7.023-2.488l67.678-43.433a16.304 16.304 0 0 0 4.914-22.522Z"
      />
      <path
        fill="#00AB5E"
        d="m456.415 136.309-75.45-27.821c-8.446-3.115-17.818 1.208-20.933 9.655L249.18 418.775a16.24 16.24 0 0 0-1.644 9.1l17.581 118.731 97.858-10.673-.598-97.475 103.694-281.216c3.113-8.447-1.209-17.819-9.656-20.933Z"
      />
      <g>
        <path
          fill="url(#c)"
          d="M336.777 501.317a120.32 120.32 0 0 0-75.784 17.885s-16.822 183.3 26.183 217.071c16.616 13.048 64.096 10.046 89.365-2.636 5.517-2.769 9.98-5.995 12.827-9.62 33.747-42.976-26.184-217.071-26.184-217.071a120.28 120.28 0 0 0-26.407-5.629Z"
        />
      </g>
      <path
        fill="#0D5C48"
        d="M333.313 688.695a9.313 9.313 0 0 1-5.388-.928 5.7 5.7 0 0 1-2.142-1.885 5.999 5.999 0 0 1-1.002-2.722 1.28 1.28 0 0 1 .255-.919 1.18 1.18 0 0 1 .806-.457c.317-.035.636.064.885.273.25.209.411.512.447.842.293 2.688 3.251 3.605 5.872 3.32 2.622-.286 5.312-1.817 5.019-4.508a1.28 1.28 0 0 1 .254-.919c.199-.258.489-.422.806-.457.317-.034.636.064.886.273.25.209.41.512.446.842.455 4.192-3.248 6.82-7.144 7.245Z"
      />
      <path
        fill="#FFA1AC"
        d="M389.09 676.312c.556 5.076-4.303 9.774-10.854 10.492-6.551.717-12.312-2.816-12.868-7.893-.557-5.077 4.302-9.774 10.853-10.492 6.551-.718 12.312 2.816 12.869 7.893ZM298.345 686.21c.556 5.077-4.303 9.774-10.854 10.492-6.55.718-12.312-2.816-12.868-7.892-.557-5.077 4.303-9.775 10.853-10.492 6.551-.718 12.312 2.816 12.869 7.892Z"
      />
    </g>
    <path
      fill="#0D5C48"
      d="M394.16 548.427a4.196 4.196 0 0 1-3.068-.891 26.103 26.103 0 0 0-24.073-4.02 4.218 4.218 0 0 1-3.389.097 4.208 4.208 0 0 1-2.358-2.437 4.201 4.201 0 0 1 2.849-5.512 34.419 34.419 0 0 1 32.225 5.286 4.194 4.194 0 0 1 1.419 4.453 4.198 4.198 0 0 1-3.576 3.01l-.029.014ZM242.7 564.947a4.204 4.204 0 0 1-3.716-6.823 34.43 34.43 0 0 1 30.329-12.109 4.204 4.204 0 0 1 2.193 7.656 4.212 4.212 0 0 1-3.331.636 26.086 26.086 0 0 0-22.645 9.075 4.179 4.179 0 0 1-2.83 1.565Z"
    />
    <path
      fill="#EE4F8E"
      d="M352.851 563.009c-12.774 1.394-24.639 13.54-22.998 28.584 1.351 12.389 8.965 20.89 16.645 27.648a133.42 133.42 0 0 0 21.69 15.49c5.427 2.961 10.795 2.376 15.235-1.661a133.524 133.524 0 0 0 17.832-19.781c6.041-8.265 11.644-18.197 10.293-30.587-1.641-15.043-15.846-24.346-28.619-22.953a21.016 21.016 0 0 0-14.356 7.845 21.03 21.03 0 0 0-7.396-3.888 21.025 21.025 0 0 0-8.326-.697ZM256.105 573.561c-12.773 1.393-24.639 13.54-22.998 28.583 1.351 12.39 8.966 20.89 16.645 27.648a133.477 133.477 0 0 0 21.681 15.502c5.427 2.961 10.795 2.375 15.235-1.662a133.424 133.424 0 0 0 17.851-19.793c6.041-8.265 11.645-18.197 10.293-30.587-1.64-15.043-15.846-24.346-28.619-22.953a21 21 0 0 0-14.356 7.845 21.012 21.012 0 0 0-15.732-4.583Z"
    />
    <path
      fill="#fff"
      d="M245.883 587.584c-3.577.39-6.9 3.792-6.441 8.005.379 3.47 2.511 5.85 4.662 7.743a37.376 37.376 0 0 0 6.072 4.341c1.52.829 3.023.665 4.266-.465a37.359 37.359 0 0 0 5-5.543c1.692-2.315 3.261-5.097 2.883-8.566-.46-4.213-4.438-6.819-8.015-6.428a5.877 5.877 0 0 0-4.021 2.196 5.896 5.896 0 0 0-4.406-1.283ZM342.319 578.843c-3.577.39-6.9 3.792-6.441 8.005.379 3.469 2.511 5.85 4.662 7.743a37.376 37.376 0 0 0 6.072 4.341c1.52.829 3.023.665 4.266-.465a37.472 37.472 0 0 0 5-5.544c1.691-2.314 3.261-5.096 2.882-8.566-.459-4.212-4.437-6.818-8.015-6.428a5.898 5.898 0 0 0-2.233.691c-.69.378-1.298.89-1.787 1.506a5.896 5.896 0 0 0-4.406-1.283Z"
    />
    <defs>
      <linearGradient
        id="c"
        x1={305.012}
        x2={336.307}
        y1={499.737}
        y2={745.38}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C7F45D" />
        <stop offset={0.573} stopColor="#E8F9AF" />
        <stop offset={0.797} stopColor="#FFF6E7" />
        <stop offset={0.953} stopColor="#FFF6E7" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgComponent;
