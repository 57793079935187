import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1728}
    height={290}
    fill="none"
    viewBox="0 0 1728 290"
    preserveAspectRatio="meet"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill={props.fill || "#C7F45D"}
        d="m0 34.547 103.928 125.282c119.956 144.603 339.079 152.538 469.179 16.991C676.865 68.718 842.459 48.78 968.912 129.162l124.268 78.992c110.87 70.481 258.27 31.924 320.43-83.821 55.2-102.779 179.54-146.674 287.03-101.327l27.36 11.54V1393.29H0V34.546Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h1728v290H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
